/*-------------------------
    Portfolio Area 
---------------------------*/
.mesonry-list {
    margin: 0 -22px;
    .masonry_item {
        .portfolio-static {
            padding: 0 22px;
        }
    }
    &.grid-metro3 {
        .resizer {
            width: 33.33%;
            @media #{$md-layout} {
                width: 50%;
            }
            @media #{$sm-layout} {
                width: 50%;
            }
        }
        .portfolio-33-33 {
            width: 33.33%;
            @media #{$md-layout} {
                width: 50%;
            }
            @media #{$sm-layout} {
                width: 100%;
            }
        }
    }
}


.transparent_link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}


.portfolio {
    position: relative;
    @extend %transition;
    min-height: 600px;
    width: 100%;
    padding-top: 35%;
    
    @media #{$laptop-device} {
        min-height: 500px;
    }
    @media #{$lg-layout} {
        min-height: 500px;
    }
    @media #{$md-layout} {
        min-height: 450px;
    }
    @media #{$sm-layout} {
        min-height: 400px;
    }
    .thumbnail-inner{
        transition: transform .28s ease;
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        border-radius: 5px;
        cursor: pointer;

        &::before{
            background-color: var(--color-primary);
            background-image: linear-gradient(var(--color-primary) 10%, $black-color 100%);
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height : 100%;
            z-index: 5;
            opacity: 0;
            @extend %transition;
        }
    }
    .thumbnail{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 5px;
        background-color: #0a0a0a;
        background-image: url(/assets/images/portfolio/portfolio-1.jpg);
        transform: scale(1.13) translateZ(0);
        backface-visibility: hidden;
        transition: transform .28s ease;
        z-index: 4;
        &.image-1{
            background-image: url(/assets/images/portfolio/portfolio-1.jpg);
        }

        &.image-2{
            background-image: url(/assets/images/portfolio/portfolio-2.jpg);
        }

        &.image-3{
            background-image: url(/assets/images/portfolio/portfolio-3.jpg);
        }

        &.image-4 {
            background-image: url(/assets/images/portfolio/portfolio-4.jpg);
        }

        &::after{
            background-color: rgba(45,45,45,.35);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: ' ';
            z-index: 1;
            display: block;
            border-radius: 5px;
            background-color: rgba(25,25,25,.37);
            @extend %transition;
        }
    }

    .bg-blr-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-color: #0a0a0a;
        background-image: url(/assets/images/portfolio/portfolio-1.jpg);
        @extend %transition;
        transform: translateY(38px);
        opacity: 0;
        z-index: 1;

        &.image-1{
            background-image: url(/assets/images/portfolio/portfolio-1.jpg);
        }
        &.image-2{
            background-image: url(/assets/images/portfolio/portfolio-2.jpg);
        }
        &.image-3{
            background-image: url(/assets/images/portfolio/portfolio-1.jpg);
        }
        &.image-4 {
            background-image: url(/assets/images/portfolio/portfolio-1.jpg);
        }
    }

    .content {
        position: absolute;
        bottom: 40px;
        left: 40px;
        right: 40px;
        max-width: 100%!important;
        z-index: 10;
        padding: 0;
        @media #{$lg-layout} {
            left: 20px;
            right: 20px;
        }
        @media #{$md-layout} {
            left: 20px;
            right: 20px;
        }
        @media #{$sm-layout} {
            left: 20px;
            right: 20px;
        }
        .inner {
            p {
                color: #c6c9d8;
                font-size: 14px;
            }
            h4 {
                font-size: 24px;
                line-height: 36px;
                a{
                    color: #ffffff;
                }
            }
            .portfolio-button{
                @extend %transition;
                margin-top: 35px;
                transition: 0.7s;
                @media #{$large-mobile} {
                    margin-top: 25px;
                }
                a {
                    &.rn-btn{
                        color: #ffffff;
                        border-color: rgba(255,255,255,0.3);
                        &:hover{
                            border-color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }

    &.portfolio-interior-design {
        .thumbnail {
            &.image-1{
                background-image: url(/assets/images/portfolio/interior/portfolio-1.jpg);
            }
    
            &.image-2{
                background-image: url(/assets/images/portfolio/interior/portfolio-2.jpg);
            }
    
            &.image-3{
                background-image: url(/assets/images/portfolio/interior/portfolio-3.jpg);
            }
    
            &.image-4 {
                background-image: url(/assets/images/portfolio/interior/portfolio-4.jpg);
            }
            &.image-5 {
                background-image: url(/assets/images/portfolio/interior/portfolio-5.jpg);
            }
            &.image-6 {
                background-image: url(/assets/images/portfolio/interior/portfolio-6.jpg);
            }
            &.image-7 {
                background-image: url(/assets/images/portfolio/interior/portfolio-7.jpg);
            }
        }
        .bg-blr-image{
            background-image: url(/assets/images/portfolio/interior/portfolio-1.jpg);
            &.image-1{
                background-image: url(/assets/images/portfolio/interior/portfolio-1.jpg);
            }
    
            &.image-2{
                background-image: url(/assets/images/portfolio/interior/portfolio-2.jpg);
            }
    
            &.image-3{
                background-image: url(/assets/images/portfolio/interior/portfolio-3.jpg);
            }
    
            &.image-4 {
                background-image: url(/assets/images/portfolio/interior/portfolio-4.jpg);
            }
            &.image-5 {
                background-image: url(/assets/images/portfolio/interior/portfolio-5.jpg);
            }
            &.image-6 {
                background-image: url(/assets/images/portfolio/interior/portfolio-6.jpg);
            }
            &.image-7 {
                background-image: url(/assets/images/portfolio/interior/portfolio-7.jpg);
            }
        }
    }

    &.text-center {
        .content {
            position: absolute;
            bottom: 40px;
            left: 40px;
            max-width: 80% !important;
            z-index: 10;
            padding: 0;
            right: 40px;
            margin: 0 auto;
            @media #{$lg-layout} {
                left: 20px;
                right: 20px;
            }
            @media #{$md-layout} {
                left: 15px;
                right: 15px;
            }
            @media #{$sm-layout} {
                left: 10px;
                right: 10px;
            }
        }
    }
    &:hover{
        .thumbnail-inner{
            transform: scale(1.08) translateZ(0);
            &::before{
                opacity: 0.85;
            }
        }
        .thumbnail {
            transform: scale(1) translateZ(0);
            &::after{
                background-color: rgba(25,25,25,.24);
            }
        }
        .bg-blr-image{
            opacity: 0;
            z-index: 9;
        }
        
    }
    &.portfolio-style--2{
        .inner{
            margin: 0 20px;
        }
    }
}


// Minimal Portfolio 

.portfolio-tilthover {
    .im_portfolio {
        margin-top: 30px;
    }
}




.portfolio-sacousel-inner {
    .slick-list{
        margin-left: -200px;
        margin-right: -200px;
        @media #{$md-layout} {
            margin-left: 15px;
            margin-right: 15px;
        } 
        @media #{$lg-layout} {
            margin-left: 15px;
            margin-right: 15px;
        } 
        @media #{$sm-layout} {
            margin-left: 10px;
            margin-right: 10px;
        } 
    }
}



.portfolio-sacousel-inner{
    .slick-slide {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: -10px;

        @media #{$lg-layout} {
            padding-left: 15px;
            padding-right: 15px;
        }

        @media #{$md-layout} {
            padding-left: 15px;
            padding-right: 15px;
        }

        @media #{$sm-layout} {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.portfolio-sacousel-inner {
    .slick-list {
        overflow: visible;
    }
}



.portfolio-sacousel-inner ,
.rn-slick-dot {
    .slick-dots {
        bottom: -60px;
        @media #{$large-mobile} {
            bottom: -50px; 
        }
        li{
            width: 30px;
            margin: 0;
            opacity: .35;
            position: relative;
            
            button{
                &::before{
                    display: none;
                }
                &::after{
                    background: #333;
                    width: 100%;
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 6px;
                    height: 6px;
                    @extend %transition;
                    border-radius: 50%;
                    margin-left: 11px;
                    background-color: transparent;
                    box-shadow: inset 0 0 0 5px $black-color;
                }
            }
            &.slick-active{
                opacity: 1;
                button{
                    &::after{
                        -webkit-transform: scale(2);
                        transform: scale(2);
                        box-shadow: inset 0 0 0 1px var(--color-primary);
                        background-color: transparent;
                        background-color: transparent!important;
                    }
                }
            }
        }
    }
    // Dot Light  
    &.dot-light {
        .slick-dots {
            li{
                button {
                    &::after {
                        background: #ffffff;
                        box-shadow: inset 0 0 0 5px #ffffff;
                    }
                }
                &.slick-active{
                    opacity: 1;
                    button{
                        &::after{
                            -webkit-transform: scale(2);
                            transform: scale(2);
                            box-shadow: inset 0 0 0 1px var(--color-primary);
                            background-color: transparent;
                            background-color: transparent!important;
                        }
                    }
                }
            }
        }
    }
}

/* Slick Arrow Button  */

.portfolio-slick-activation {
    position: relative;
    button {
        &.slick-arrow {
            position: absolute;
            z-index: 3;
            background: transparent;
            font-size: 20px;
            width: 253px;
            height: 101%;
            
            @media #{$lg-layout} {
                width: 136px;
            }

            @media #{$md-layout} {
                width: 100px;
            }

            @media #{$sm-layout} {
                width: 100px;
                display: none !important;
            }

            &::before {
                line-height: 1;
                opacity: 0.75;
                font-size: 30px;
                position: relative;
                top: -14px;
                transition: 0.5s;
            }
            &.slick-prev{
                left: 0;
                background: -moz-linear-gradient(left,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                background: -webkit-linear-gradient(left,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
                background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$black-color', endColorstr='$black-color00',GradientType=1 );
                &::before{
                    content: url(../images/icons/slick-arrow-left.png);
                }
                @media #{$md-layout} {
                    display: none !important;
                }
                @media #{$lg-layout} {
                    display: none !important;
                }
                @media #{$laptop-device} {
                    display: none !important;
                }
                
            }
            &.slick-next{
                right: 0;
                background: -moz-linear-gradient(right,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                background: -webkit-linear-gradient(right,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
                background: linear-gradient(to left,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$black-color', endColorstr='$black-color00',GradientType=1 );
                &::before{
                    content: url(../images/icons/slick-arrow-right.png);
                }
                @media #{$md-layout} {
                    display: none !important;
                }
                @media #{$lg-layout} {
                    display: none !important;
                }
                @media #{$laptop-device} {
                    display: none !important;
                }
            }
            &:hover{
                &.slick-prev{
                    padding-right: 50px;

                    @media #{$md-layout} {
                        padding-right: 10px;
                    }
                    @media #{$sm-layout} {
                        padding-right: 10px;
                    }
                }
                &.slick-next {
                    padding-left: 50px;
                    @media #{$md-layout} {
                        padding-right: 10px;
                    }
                    @media #{$sm-layout} {
                        padding-right: 10px;
                    }
                }
                &::before{
                    opacity: 1;
                }
            }
        }
    }
}


/* Slick Gutter Space  */
.slick-space-gutter--15 {
    margin-left: -15px;
    margin-right: -15px;
    .slick-slide {
        padding-left: 15px;
        padding-right: 15px;
    }
    .portfolio,
    .single_im_portfolio {
        margin: 50px 0;
    }
}
.slickdot--20 {
    .slick-dots { 
        bottom: -20px;
    }
}

/*-----------------------------
Portfolio Style Three  
--------------------------------*/
.portfolio-style--3 {
    position: relative;
    margin-bottom: 40px;
    @extend %transition;
    overflow: hidden;
    border-radius: 6px;
    .thumbnail {
        a {
            display: block;
            position: relative;
            z-index: 1;
            img{
                @extend %transition;
            }
            &::after {
                background: linear-gradient(to bottom, var(--color-primary) 0, $black-color 100%);
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                content: "";
                z-index: 1;
                opacity: 0;
                @extend %transition;
                @media #{$large-mobile} {
                    opacity: 0.85;
                    top: 0;
                }

            }
        }
    }
    .content{
        position: absolute;
        bottom: -92px;
        width: 100%;
        padding: 50px 35px;
        z-index: 3;
        @extend %transition;
        @media #{$large-mobile} {
            bottom: 0;
        }
        @media #{$large-mobile} {
            padding: 30px 20px;
        }
        &::before {
            position: absolute;
            content: "";
            background: linear-gradient(to bottom, rgba(0,0,0,0.0) 0, $black-color 70%);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            @extend %transition;
            z-index: -1;
            @media #{$large-mobile} {
                opacity: 0;
            }
        }
        p{
            &.portfoliotype{
                font-size: 14px;
                margin-bottom: 5px;
                color: #c6c9d8;
            }
        }
        h4{
            &.title{
                margin-bottom: 0;
                a{
                    color: #ffffff;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 36px;
                    @extend %transition;
                }
            }
        }
        .portfolio-btn {
            @extend %transition;
            margin-top: 35px;
            transition: 0.7s;
            opacity: 0;
            visibility: hidden;
            
            @media #{$large-mobile} {
                margin-top: 22px;
            }
            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &:hover {
        .content {
            bottom: 0;
            &::before{
                opacity: 0;
            }
        }
        .thumbnail {
            a {
                img{
                    transform: scale(1.1);
                }
                &::before{
                    opacity: 0;
                }
                &::after{
                    opacity: 0.85;
                    top: 0;
                }
            }
        }
        .content{
            .portfolio-btn {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.row--5 {
    .portfolio-style--3 {
        margin-bottom: 10px;
    }
}


/* Portfolio Style Static */
.item-portfolio-static {
    margin-top: 60px;
    @media #{$sm-layout} {
        margin-top: 30px;
    }
    @media #{$md-layout} {
        margin-top: 30px;
    }
    .inner {
        padding-top: 20px;

        p {
            margin-bottom: 5px;
            font-size: 16px;
            color: var(--color-gray);
            font-weight: 400;
            transition: 0.3s;
            &:hover {
                color: var(--color-primary);
            }
        }
        h4 {
            color: $heading-color;
            transition: 0.3s;
            &:hover {
                color: var(--color-primary);
            }
            a {
                color: $heading-color;
                transition: 0.3s;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
    .thumbnail {
        a{
            img {
                @extend %transition;
            }
        }
        img {
            @extend %transition;
        }
    }
    &:hover {
        .thumbnail {
            a {
                img {
                    transform: translate3d(0, -10px, 0);
                    box-shadow: 0 50px 80px -10px rgba(0,0,0,0.17);
                }
            }
            img {
                transform: translate3d(0, -10px, 0);
                box-shadow: 0 50px 80px -10px rgba(0,0,0,0.17);
            }
        }
    }

}




/*------------------------------
    Portfolio Style For Imroz 
--------------------------------*/
.im_portfolio {
    position: relative;
    z-index: 2;
    overflow: hidden;
    border-radius: 10px;
    transition: transform .65s cubic-bezier(.05,.2,.1,1),box-shadow .65s cubic-bezier(.05,.2,.1,1);

    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 85%;
        display: block;
        z-index: 1;
        content: '';
        background: linear-gradient(to bottom,rgba(15,15,15,0),rgba(15,15,15,.75) 100%);
        transition: opacity .65s cubic-bezier(.05,.2,.1,1);
        cursor: pointer;
    }
    
    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 85%;
        display: block;
        z-index: 1;
        content: '';
        background: linear-gradient(to bottom,rgba(0,0,0,0),var(--color-primary) 100%);
        transition: opacity .65s cubic-bezier(.05,.2,.1,1);
        cursor: pointer;
        opacity: 0;
    }

    .thumbnail_inner {
        .thumbnail {
            a {
                display: block;
                img {
                    transition: transform 1s ease,opacity 0.5s ease 0.25s;
                    border-radius: 10px;
                    width: 100%;
                }
            }
        }
    }

    .content {
        .inner {
            position: absolute;
            bottom: 0;
            left: 50%;
            padding: 30px 30px;
            z-index: 2;
            width: 100%;
            transform: translateX(-50%);
            @media #{$sm-layout} {
                padding: 27px 24px;
            }

            .portfolio_heading {
                transition: opacity .65s cubic-bezier(.05,.2,.1,1),transform .65s cubic-bezier(.05,.2,.1,1);
                .category_list {
                    margin: -5px;
                    margin-bottom: 5px;
                    a {
                        color: #ffffff;
                        opacity: 0.7;
                        display: inline-block;
                        margin: 5px;
                        font-size: 16px;
                        font-weight: 300;
                        letter-spacing: 0.5px;
                    }
                }
                .title {
                    color: #ffffff;
                    font-size: 30px;
                    a  {
                        color: #ffffff;
                    }
                    @media #{$lg-layout} {
                        font-size: 24px;
                        margin-bottom: 0;
                    }
                    @media #{$md-layout} {
                        font-size: 24px;
                        margin-bottom: 0;
                    }
                    @media #{$sm-layout} {
                        font-size: 22px;
                        margin-bottom: 0;
                    }
                }
            }
            .portfolio_hover {
                opacity: 0;
                position: absolute;
                bottom: 35px;
                margin-top: 10px;
                transform: translateY(20px);
                line-height: 1.5em;
                max-width: 80%;
                transition: opacity 0.18s cubic-bezier(0.05, 0.2, 0.1, 1),transform 0.18s cubic-bezier(0.05, 0.2, 0.1, 1);
                height: 62px;
                text-overflow: ellipsis;
                overflow: hidden;
                @media #{$sm-layout} {
                    bottom: 43px;
                }
                p {
                    color: #ffffff;
                    opacity: 0.7;
                }
            }

        }
    }

    &.text-center {
        .content {
            .inner {
                .portfolio_hover {
                    @media #{$md-layout} {
                        max-width: 100%;
                        left: 0;
                        right: 0;
                    }
                    @media #{$sm-layout} {
                        max-width: 100%;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }


    
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 25px 55px rgba(253, 71, 102, 0.22);
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }

        .thumbnail_inner {
            .thumbnail {
                a {
                    img {
                        transform: scale(1.1);
                        transition: all 9s cubic-bezier(.1,.2,7,1);
                    }
                }
            }
        }

        .content {
            .inner {
                .portfolio_heading {
                    transform: translateY(-62px);
                    transition: opacity .65s cubic-bezier(.05,.2,.1,1),transform .65s cubic-bezier(.05,.2,.1,1);
                    @media #{$sm-layout} {
                        transform: translateY(-91px);
                    }
                }
                .portfolio_hover {
                    transform: translateY(0);
                    opacity: 1;
                    transition: opacity .65s cubic-bezier(.05,.2,.1,1) 0.15s,transform .65s cubic-bezier(.05,.2,.1,1) 0.15s;
                }

            }
        }
    }

}




.single_im_portfolio {

}
