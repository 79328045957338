/*====================
    Mainmenu Area 
=====================*/

.mainmenunav {
    ul {
        &.mainmenu {
            display: flex;
            justify-content: flex-end;
            @extend %liststyle;
            flex-wrap: wrap;

            > li {
                margin: 0 15px;
                position: relative;
                @media #{$lg-layout} {
                    margin: 0 12px;
                }

                > a {
                    @extend %transition;
                    color: var(--color-white);
                    font-size: 16px;
                    font-weight: 500;
                    padding: 20px 0;
                    display: inline-block;
                }

                ul {
                    &.submenu {
                        @extend %liststyle;
                        min-width: 240px;
                        height: auto;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        z-index: -2;
                        opacity: 0;
                        visibility: hidden;
                        background-color: #fff;
                        box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
                        text-align: left;
                        @extend %transition;
                        padding: 12px 0;
                        border-radius: 4px;

                        li {
                            a {
                                font-size: 16px;
                                font-weight: 500;
                                padding: 5px 20px;
                                font-size: 14px;
                                display: block;
                                color: $heading-color;
                                margin: 0 10px;
                                border-radius: 3px;
                                @extend %transition;
                            }

                            &:hover {
                                >a {
                                    color: var(--color-primary);
                                    background: rgba(249, 0, 77, 0.07);
                                }
                            }
                        }
                    }
                }

                &:hover {
                    >ul {
                        &.submenu {
                            opacity: 1;
                            visibility: visible;
                            z-index: 90;
                        }
                    }
                }

                &:hover {
                    >a {
                        color: var(--color-primary);
                    }
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.onepagenav {
                    li {
                        a {
                            position: relative;

                            &::after {
                                position: absolute;
                                content: "";
                                left: 0;
                                width: 0;
                                height: 3px;
                                background: var(--color-primary);
                                -webkit-transition: .3s;
                                transition: .3s;
                                bottom: 0;
                            }
                        }

                        &.current,
                        &:hover {
                            a {
                                color: var(--color-primary) !important;

                                &::after {
                                    width: 100% !important;
                                }
                            }
                        }
                    }
                }

                ul {
                    &.submenu {
                        li {
                            ul {
                                &.submenu {
                                    left: 100%;
                                    right: auto;
                                    top: 0;
                                }
                            }

                            &:hover {
                                >ul {
                                    &.submenu {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.humberger-menu {
    span {
        &.text-white {
            color: #ffffff !important;
        }
    }
}

.color-white {
    .mainmenunav {
        ul {
            &.mainmenu {
                @extend %liststyle;
                > li {
                    > a {
                        color: #ffffff;
                    }
                    &:hover {
                        > a {
                            color: rgba(255, 255, 255, 0.6);
                            @media #{$md-layout} {
                                color: var(--color-primary);
                            }
                            @media #{$sm-layout} {
                                color: var(--color-primary);
                            }
                        }
                    }
                }
            }
        }
    }
}

.color-black {
    .mainmenunav {
        ul {
            &.mainmenu {
                @extend %liststyle;

                >li {
                    >a {
                        color: rgba(29, 29, 36, 1);
                    }

                    &:hover {
                        >a {
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}

@media #{$lg-layout} {
    .header-style-two .humberger-menu span.text-white {
        color: $body-color !important;
    }
}

@media #{$md-layout} {
    .header-style-two .humberger-menu span.text-white {
        color: $body-color !important;
    }
}

@media #{$sm-layout} {
    .header-style-two .humberger-menu span.text-white {
        color: $body-color !important;
    }
}